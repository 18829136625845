// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-propos-js": () => import("./../src/pages/a-propos.js" /* webpackChunkName: "component---src-pages-a-propos-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nos-realisations-js": () => import("./../src/pages/nos-realisations.js" /* webpackChunkName: "component---src-pages-nos-realisations-js" */),
  "component---src-pages-piece-detachee-js": () => import("./../src/pages/piece-detachee.js" /* webpackChunkName: "component---src-pages-piece-detachee-js" */),
  "component---src-pages-support-technique-js": () => import("./../src/pages/support-technique.js" /* webpackChunkName: "component---src-pages-support-technique-js" */),
  "component---src-templates-category-js": () => import("./../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

